
  .serendipity-bg {
    width: 100vw;
    height: 100vh;
    background: url(https://cos.qinglan.space/serendipity-bg-top.png);
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    background-color: #87c1f4;
    position: absolute;
    top: 0;
    left: 0;
  }
  #simple-tabpanel-1 {
    background: url(https://cos.qinglan.space/serendipity-color.png);
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    background-color: #87c1f4;
  }
  .serendipity-logo {
    width: 100vw;
    height: 14vh;
    margin-top: 3vh;
    background: url(https://cos.qinglan.space/serendipity-logo.png);
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
  }
  .serendipity-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 10vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .serendipity-bottom-button {
    margin-top: 2vh;
    .MuiButton-containedPrimary {
      color: #fff;
      background-color: #ffc500 !important;
    }
  }
  .serendipity-bottom-logo {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 13vh;
    background: url(https://cos.qinglan.space/serendipity-bottom.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .serendipity-bottom-tab {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 5vh;
    background-color: #fff;
  }
  .MuiBox-root p,
  .MuiImageList-masonry {
    margin-block-start: 0;
  }
  .MuiTabs-indicator {
    background-color: #ffc500 !important;
  }
  .MuiImageListItemBar-subtitle  {
    font-size: .6rem !important;
    text-align: left;
  }