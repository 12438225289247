
  .message-bg {
    width: 100vw;
    height: 100vh;
    background: url(https://cos.qinglan.space/message-bg.jpg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #f7dd3c;
  }
  .f-button {
    width: fit-content;
    background-color: #323232;
    padding: 10pt;
  }
  .MuiImageListItem-img {
    width: 30vw;
  }
  .css-dasnyc-MuiImageListItemBar-title {
    font-size: .6rem !important; 
    text-align: left;
  }