@font-face {
  font-family: "REEJI-Xiaodou-SeaGB-Flash-Regular";  
  src: url("https://api.qinglan.space/birthday/XiaoDouDaoChunRiHaiJian-Shan-ChangGui(REEJI-Xiaodou-SeaGB-Flash-Regular)-2.ttf") format("truetype");
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.card-text {
  color: #fff;
  font-size: calc(1vw + 1.8vh + .5vmin);
  max-height: 90vh;
  max-width: 400px;
  overflow: hidden;
  min-height: 60vh;
  margin-bottom: 10vh;
  margin-top: 10vh;
}
.write-content {
  width: 100vw;
  height: 100vh;
  background: url(https://cos.qinglan.space/birthday-no.pic.jpg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #a09cfa;
}