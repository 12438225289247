@font-face {
    font-family: "REEJI-Xiaodou-SeaGB-Flash-Regular";
    src: url("https://api.qinglan.space/birthday/XiaoDouDaoChunRiHaiJian-Shan-ChangGui(REEJI-Xiaodou-SeaGB-Flash-Regular)-2.ttf")
      format("truetype");
  }
  
  .App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .home-bg {
    width: 100vw;
    height: 100vh;
    background: url(https://cos.qinglan.space/birthday-bg.jpg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #a09cfa;
  }
  
  .select-container .MuiInput-root {
    font-size: 2rem;
    color: rgb(255, 255, 255);
  }
  
  .MuiButton-containedPrimary {
    color: #fff;
    background-color: #716bf3 !important;
  }